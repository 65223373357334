import { ref, Ref } from 'vue';
import { account, accountType } from '@/data';

const checkNameForProject = (data: Array<{ ID: string; Name: string; Grade: string }>) => {
    const checkResult = [...data];
    data.forEach((outerItem, outerIndex) => {
        data.forEach((innerItem, innerIndex) => {
            if (innerItem.Name === outerItem.Name && innerItem.Grade !== outerItem.Grade) {
                checkResult[outerIndex].Name = `${outerItem.Name}(${Number(outerItem.Grade) === account.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
                checkResult[innerIndex].Name = `${innerItem.Name}(${Number(innerItem.Grade) === account.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
            }
        });
    });
    return checkResult;
};

const init = () => {
    const installerOptions: Ref<accountType.GetAllInstallerCommunity> = ref([]);
    const communityOptions: Ref<Array<{ ID: string; Name: string; Grade: string }>> = ref([]);

    // 获取dis下安装商
    account.getAllInstallerCommunity((data: accountType.GetAllInstallerCommunity) => {
        installerOptions.value = data;
    });
    // 切换安装商，社区改动
    const changeInstaller = (ID: string) => {
        installerOptions.value.forEach((item) => {
            if (item.ID === ID) communityOptions.value = checkNameForProject([...item.Community, ...item.Office]);
        });
    };

    return {
        installerOptions,
        communityOptions,
        changeInstaller
    };
};

export default init;