
import {
    defineComponent, reactive, watch
} from 'vue';
import chooseInsCommunity from '@/methods/installer-community-choose';
import { listPagination, RequestData } from '@/components/common/list';
import { format } from '@/methods/date';
import router, { distributorBaseRouter, distributorSubRouter } from '@/router';
import { payProcessing } from '@/components/view/common/payment';
import {
    typeOptions, statusOptions, headers,
    listInitData
} from './util';

export default defineComponent({
    components: {
        listPagination,
        payProcessing
    },
    setup() {
        const {
            formData,
            updateToList,
            isShowPayProcessing,
            action,
            payJumpUrl
        } = listInitData();
        const {
            installerOptions,
            communityOptions,
            changeInstaller
        } = chooseInsCommunity();
        // 切换installer，community置为初始All选项
        watch(() => formData.Installer, () => {
            formData.Community = 'all';
        });

        const requestData: RequestData = reactive({
            url: 'v3/web/common/order/getList',
            param: { ...formData }
        });
        const search = () => {
            const data = { ...formData };
            data.BeginTime = format(data.BeginTime);
            data.EndTime = format(data.EndTime);
            requestData.param = data;
            updateToList.value += 1;
        };
        const successPay = () => {
            isShowPayProcessing.value = false;
            updateToList.value += 1;
        };
        const goInfo = (value: { ID: string }) => {
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.paymentInfo}?id=${value.ID}`);
        };
        return {
            formData,
            typeOptions,
            statusOptions,
            installerOptions,
            communityOptions,
            changeInstaller,
            headers,
            updateToList,
            requestData,
            isShowPayProcessing,
            action,
            successPay,
            search,
            goInfo,
            payJumpUrl
        };
    }
});
