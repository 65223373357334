import { ref, reactive } from 'vue';
import { ListHeaderItem, ListActionItem } from '@/components/common/list';
import noticeBase from '@/util/notice.base';
import { cancel } from '@/components/view/common/payment';

const typeOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '1',
    label: WordList.ProperAllTextActivate
}, {
    value: '2',
    label: WordList.ProperAllTextRenew
}, {
    value: '5',
    label: WordList.ProperAllTextFeature
}];

const statusOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '0',
    label: WordList.ProperAllTextProcessing
}, {
    value: '1',
    label: WordList.ProperAllTextSuccessed
}, {
    value: '2',
    label: WordList.PropertyAllTextFailed
}, {
    value: '3',
    label: WordList.ProperAllTextTimeOut
}, {
    value: '5',
    label: WordList.TabelFootCancel
}, {
    value: '6',
    label: WordList.ProperAllTextSystemProcessing
}];

const headers: ListHeaderItem[] = [{
    name: 'OrderNumber',
    label: WordList.OrderOrderNumber
}, {
    name: 'Type',
    label: WordList.TabelKeyInHtmlType
}, {
    name: 'Installer',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Projects',
    label: WordList.ProperAllTextProject,
    type: 'customize'
}, {
    name: 'AptNumber',
    label: WordList.ProperAllTextCount
}, {
    name: 'TotalPrice',
    label: WordList.OrderTotalPrice
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

const listInitData = () => {
    const formData = reactive({
        Type: 'all',
        Status: 'all',
        BeginTime: '',
        EndTime: '',
        Installer: 'all',
        Community: 'all',
        Key: ''
    });
    const updateToList = ref(0);
    const isShowPayProcessing = ref(false);
    const payJumpUrl = ref('');
    const action: ListActionItem[] = [{
        type: 'pay',
        event(val: { bmurl: string }) {
            isShowPayProcessing.value = true;
            payJumpUrl.value = val.bmurl;
            window.open(val.bmurl);
        },
        class: 'el-icon-my-pay modify-icon',
        showCondition(val: { StatusEnum: string }) {
            return val.StatusEnum === '0';
        },
        title: WordList.OrderPay
    },
    {
        type: 'cancelpay',
        event(val: {OrderNumber: string; BmOrderNumber: string; PayCode: string}) {
            noticeBase.messageBox('confirm', WordList.TabelPaymentCancel, WordList.TabelDeleteTitle, 'warning')(() => {
                cancel.cancelOrder({ OrderNumber: val.OrderNumber }, () => {
                    updateToList.value += 1;
                });
            });
        },
        class: 'el-icon-my-cancel-payment cancelpay-icon',
        showCondition(val: { StatusEnum: string }) {
            return val.StatusEnum === '0';
        },
        title: WordList.TabelFootCancel
    }];

    return {
        formData,
        updateToList,
        isShowPayProcessing,
        action,
        payJumpUrl
    };
};

export default null;
export {
    typeOptions,
    statusOptions,
    headers,
    listInitData
};